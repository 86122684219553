<template>
    <sweet-modal
      ref="validationErrorMessage"
      icon="error"
      overlay-theme="dark"
    >
      <div class="termsOfUseContent">
        <h1 class="Headline is-size-4" v-t="'validationErrorMessage.title'" />
        <p  v-html="$t('validationErrorMessage.infoMessage')" />
        <p v-for="error in Object.keys(errors)" :key="error">{{ $t(`validationErrorMessage.errors.${error}`) }}</p>
      </div>
        <div class="row">
            <button type="button" class="Button" @click="close">
              {{ $t('validationErrorMessage.closeButtonText') }}
            </button>
        </div>
    </sweet-modal>
</template>
<script>
export default {
  name: 'ValidationErrorMessage',

  data () {
    return {
    }
  },

  props: {
    errors: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    async close () {
      this.$refs.validationErrorMessage.close()
    },

    open () {
      this.$refs.validationErrorMessage.open()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.linkButton, .linkButton:hover{
  background:none;
  color:#979594;
}

.row {
  margin-top: 10px;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}
</style>
