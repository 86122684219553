<template>
  <div>
    <Form
      v-if="!registered"
      v-slot="slotProps"
      ref="form"
      @submit="submitProfessionalRegistration"
      class="Step"
    >
      <step-list
        ref="steps"
        :step="step"
        :steps="steps"
        v-on:triggerPrevStep="this.prevStep()"
      />
      <transition :name="formTransition" mode="out-in">
        <div>
          <!-- step 1 -->
          <div v-show="isStepOne">
            <h1
              v-if="isEdit"
              class="Headline is-size-5"
              v-t="'registration.recipient.information'"
            />

            <p
              v-if="isEdit && countryHasFullRegistration()"
              v-html="translateWithMymedelString('pages.profileEdit.info')"
            />

            <strong
              v-if="!isEdit"
              class="Headline is-size-5 d-block"
              v-t="'registration.recipient.title'"
            />
            <input-select-component
              :fieldName="fieldNames.iAm"
              :fieldLabel="iAmFieldLabel"
              :options="userTypes"
              @input="$store.commit('user/setUserType', $event.code)"
            />
            <input-component :fieldName="fieldNames.email" :fieldLabel="emailFieldLabel" :rules="`required|email`" type="email" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.firstName" :fieldLabel="firstNameFieldLabel" :rules="`required|max:20`" type="text" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.lastName" :fieldLabel="lastNameFieldLabel" :rules="`required|max:20`" type="text" :errors="slotProps"/>

            <input-select-component
              :fieldName="fieldNames.country"
              :fieldLabel="countryFieldLabel"
              :options="countries"
              @input="updateCountryField($event)"
            />
          </div>
        </div>
        </transition>
        <!-- step 2 -->
        <transition :name="formTransition" mode="out-in">
        <div v-show="isStepTwo">
          <strong
            class="Headline is-size-5 d-block"
            v-t="'registration.recipient.information'"
          />

          <p v-if="!isEdit">
            {{ $t('registration.recipient.fullAccess') }}

            <button
              type="button"
              class="link"
              v-t="'registration.recipient.basicAccess'"
              @click="submitBasicAccess"
            />
          </p>

          <InputDatepickerComponent
              :fieldName="fieldNames.dateOfBirth"
              :fieldLabel="$t('form.dateOfBirth')"
              :validationRules="isStepTwo ? 'max130|min16|required' : ''"
            />

          <strong
            class="Headline is-size-5 d-block"
            v-text="$t('device.data')"
          />

          <div class="form-checkboxes">
            <InputRadioComponent
              :fieldLabel="$t('device.implant')"
              :fieldName="'deviceType'"
              option-value="implant"
              :rules="isStepTwo ? { required: { allowFalse: false } } : ''"
              @click.native="setDeviceType('implant')"
            />

            <InputRadioComponent
              :fieldLabel="$t('device.audioProcessor')"
              :fieldName="'deviceType'"
              option-value="audio-processor"
              :rules="isStepTwo ? { required: { allowFalse: false } } : ''"
              class="has-lg-ml"
              @click.native="setDeviceType('audio-processor')"
            />
          </div>

          <input-select-component
            :errors="slotProps.errors[$t('device.name')]"
            :options="deviceOptions"
            :fieldLabel="deviceSelectorFieldLabel"
            :fieldName="fieldNames.deviceModel"
            :rules="isStepTwo ? `required` : ''"
          />
          <input-component :fieldName="fieldNames.serialNumber" :fieldLabel="serialNumberFieldLabel" :rules="isStepTwo ? `required|min_value:1|max_value:100000000` : ''" type="number" :errors="slotProps"/>

          <strong
            class="Headline is-size-5 d-block"
            v-text="$t('address.data')"
          />
          <input-component :fieldName="fieldNames.street" :fieldLabel="streetFieldLabel" :rules="isStepTwo ? `required|max:100` : ''" type="text" :errors="slotProps"/>
          <input-component :fieldName="fieldNames.street1" :fieldLabel="street1FieldLabel" :rules="isStepTwo ? `max:100` : ''" type="text" :errors="slotProps"/>
          <input-component :fieldName="fieldNames.city" :fieldLabel="cityFieldLabel" :rules="isStepTwo ? `required|max:50` : ''" type="text" :errors="slotProps"/>
          <input-component v-if="isCountryUs" :fieldName="fieldNames.state" :fieldLabel="stateFieldLabel" :rules="isStepTwo ? `required|max:50` : ''" type="text" :errors="slotProps"/>
          <input-component :fieldName="fieldNames.zipCode" :fieldLabel="zipCodeFieldLabel" :rules="isStepTwo ? `required|max:20` : ''" type="text" :errors="slotProps"/>
        </div>
      </transition>
          <button
            class="Button form-button d-block"
            :class="{ 'is-grey': !slotProps.meta.valid }"
            v-text="$t(nextLabel)"
          />

          <button
            type="button"
            v-if="step > 0"
            class="Button form-button is-link d-block"
            v-text="$t('form.prev')"
            @click="prevStep"
          />
      </Form>
      <p v-if="!isEdit">
        {{ $t('form.alreadyAccount') }} <link-router
        path="home"
        >{{ $t('form.loginNow') }}</link-router>
      </p>

    <regapp-feedback v-if="registered">
      <template #body>
        <div v-html="translateWithMymedelString(`${responseMessageKey}`)" />
      </template>

      <template #footer>

        <br>

        <link-router
          class-names="Link d-i-block text-grey mt-1"
          path="dashboard"
        >
          {{ $t('links.toDashboard.label') }}
        </link-router>
      </template>
    </regapp-feedback>
    <sweet-modal icon="warning" ref="modalError">
      <div v-html="$t('registration.messages.internal:error')" />
    </sweet-modal>
    <validation-error-message :errors="errors" ref="validationErrorMessage" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import registrationSteps from '@/mixins/registrationForm.js'
import ValidationErrorMessage from '../components/ValidationErrorMessage.vue'
import localeMessages from '@/mixins/localeMessages'
import { Form } from 'vee-validate'
import InputSelectComponent from '@/components/partials/InputSelectComponent.vue'
import InputComponent from '@/components/partials/InputComponent.vue'
import InputDatepickerComponent from '@/components/partials/InputDatepickerComponent.vue'
import InputRadioComponent from '@/components/partials/InputRadioComponent.vue'

export default {
  name: 'RegisterFormRecipient',

  props: {
    submitSuccess: {
      type: Boolean,
      default: false
    },

    isEdit: {
      type: Boolean,
      default: false
    }
  },

  mixins: [ registrationSteps, localeMessages ],

  components: {
    InputComponent,
    InputSelectComponent,
    ValidationErrorMessage,
    Form,
    InputDatepickerComponent,
    InputRadioComponent
  },

  computed: {
    ...mapGetters('user', [
      'user',
      'isBasic',
      'fullName',
      'countryString'
    ]),

    isImplantDevice () {
      return this.deviceType === 'implant'
    },

    deviceOptions () {
      return this.isImplantDevice ? this.implantOptions : this.audioProcessorOptions
    },

    implantOptions () {
      return this.getOptionValues('dropdowns.implants')
    },

    audioProcessorOptions () {
      return this.getOptionValues('dropdowns.audioProcessors')
    },

    isStepOne () {
      return this.step === 0
    },

    isStepTwo () {
      return this.step === 1
    }
  },

  data () {
    return {
      errors: {},
      step: 0,
      steps: 2,
      fullRegistrationSteps: 2,
      fullRegistration: false,
      country: null,
      dateOfBirth: '',
      deviceType: '',
      confirmations: {
        terms: false
      },
      registered: false,
      responseMessageKey: '',
      formTransition: 'form-step-left',
      userTypes: this.getOptionValues('form.userRoles', true), // TODO: define a value in form object in language file
      userType: '',
      fieldNames: {
        iAm: 'iAm',
        email: 'email',
        firstName: 'firstName',
        lastName: 'lastName',
        country: 'country',
        dateOfBirth: 'dateOfBirth',
        deviceModel: 'deviceModel',
        serialNumber: 'serialNumber',
        street: 'street',
        street1: 'street1',
        city: 'city',
        state: 'state',
        zipCode: 'zipCode'
      }
    }
  },

  mounted () {
    if (this.isEdit) {
      this.$refs.form.setFieldValue(this.fieldNames.iAm, this.user.userType)
      this.$refs.form.setFieldValue(this.fieldNames.firstName, this.user.firstName)
      this.$refs.form.setFieldValue(this.fieldNames.lastName, this.user.lastName)
      this.$refs.form.setFieldValue(this.fieldNames.email, this.user.email)

      this.country = this.countries.find(i => i.code === this.user.country)
      this.$refs.form.setFieldValue(this.fieldNames.country, this.country)
      this.language = this.$i18n.locale ? this.$i18n.locale : 'en'
      this.userType = this.userTypes.find(x => x.code === 'Recipient')
      this.$refs.form.setFieldValue('deviceType', 'implant')
      this.$refs.form.validate()
    }
  },

  watch: {
    isImplantDevice () {
      this.form.device = null
    },
    country: function () {
      if (this.countryHasFullRegistration()) {
        this.fullRegistration = true
        this.steps = this.fullRegistrationSteps
      } else {
        this.fullRegistration = false
        this.steps = 0
      }
    }
  },

  methods: {
    ...mapActions({
      loadUserProfile: 'user/getUserFromDB',
      loadServiceClaims: 'user/getServiceClaims'
    }),

    setDeviceType (devicetype) {
      if (this.deviceType === devicetype) {
        return
      }
      this.deviceType = devicetype
      this.$refs.form.setFieldValue(this.fieldNames.deviceModel, '')
    },

    prevStep () {
      this.formTransition = 'form-step-right'
      this.step = Math.max(0, this.step - 1)
      this.scrollUp()
    },

    updateCountryField (value) {
      this.country = value
    },

    async submitProfessionalRegistration (values) {
      let tempValues = Object.assign({}, this.$refs.form.getValues())
      if (this.step + 1 < this.steps) {
        this.$refs.form.resetForm({ errors: {}, values: tempValues })
        return this.nextStep()
      }

      let success = false
      // in countries without full registration, we just update the profile
      if (this.fullRegistration) {
        // since the user might have changed some data from the profile, we also have to patch the user profile
        let data = this.getFullRequestObject(values)
        var patchedProfileSuccessful = await this.patchUserProfile(data)
        if (patchedProfileSuccessful) {
          success = await this.postUpgrade(data)
        }
      } else {
        let data = this.getBasicRequestObject(values)
        success = await this.patchUserProfile(data)
      }
      if (success) {
        this.responseMessageKey = `registration.messages.upgrade`
        this.registered = true
        this.scrollUp()
        try {
          await this.loadUserProfile()
          await this.loadServiceClaims()
        } catch (err) {
          // Add error handling if needed
        }
      }
    },

    async submitBasicAccess () {
      await this.sendUpgradeRequest(this.getBasicRequestObject('basic'))
    },

    getOptionValues (translationKey, asObject = false) {
      const entries = this.$tm(translationKey)

      return Object.keys(entries).map(key => {
        return asObject ? {
          code: key,
          label: entries[key]
        } : entries[key]
      })
    },

    getFullRequestObject (values) {
      let basicFields = this.getBasicRequestObject(values)
      return Object.assign(basicFields, {
        registrationType: 'full',
        dateOfBirth: values[this.fieldNames.dateOfBirth],
        deviceType: values['deviceType'],
        device: values[this.fieldNames.deviceModel],
        deviceSerialNumber: values[this.fieldNames.serialNumber],
        street: values[this.fieldNames.street]?.trim(),
        street1: values[this.fieldNames.street1]?.trim(),
        city: values[this.fieldNames.city]?.trim(),
        state: values[this.fieldNames.state]?.trim(),
        zipCode: values[this.fieldNames.zipCode]?.trim()
      })
    },
    getBasicRequestObject (values) {
      return {
        registrationType: 'basic',
        email: values[this.fieldNames.email]?.trim(),
        firstName: values[this.fieldNames.firstName]?.trim(),
        lastName: values[this.fieldNames.lastName]?.trim(),
        userType: 'Recipient',
        language: this.$i18n.locale ? this.$i18n.locale : 'en',
        country: values[this.fieldNames.country].code
      }
    }
  }
}

</script>
